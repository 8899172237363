import React, {Component} from 'react'
import classNames from 'classnames'
import {$$} from '../../helpers/localization';
import moment from 'moment'
import _ from 'underscore';
import NoteSpeechToText from './NoteSpeechToText';
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import { Links } from '../messages/chatBoxWithSelectedUser/Message';


class NotesTabListItem extends Component {
    state = {
        encounter: null,
        descriptionOpened: false,
        isEditMode: false,
        value: '',
        note: '',
        formclass: '',
        errors: {},
        changingNote: false,
    }

    constructor(props) {
        super(props);
        this.state.owner_fullname = this.getOwnerName();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedNoteId !== this.props.note.id && this.state.encounter != null) {
            this.setState({
                encounter: null,
                descriptionOpened: false
            });
        }
        if (this.props.selectedNoteId !== this.props.note.id && this.state.encounter == null && this.state.descriptionOpened !== false) {
            this.setState({descriptionOpened: false});
        }
        if (this.props.selectedNote && ((prevProps.selectedNote !== this.props.selectedNote) ||
            (prevProps.selectedNote === this.props.selectedNote && (!this.state.value && this.props.selectedNote.value)))) {
            this.setState({value: this.props.selectedNote.value});
            this.setState({note: this.props.selectedNote.value});
        }
        if (this.props.selectedNoteId !== this.props.note.id && this.state.isEditMode) {
            this.setState({
                isEditMode: false
            });
        }

        if (this.props.note.id !== prevProps.note.id) {
            this.setState({owner_fullname:this.getOwnerName()})
        }
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create logbook entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        this.setState({errors: ''});
        if (!this.state.note) {
            errors.note = 'note_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;

    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    onNoteSpeechChange = (text) => {
        this.setState({note: text});
    };

    /**
     * An event handler triggered when an note is clicked
     */
    onNoteClick = () => {
        this.props.onNoteChange(this.props.note, this.props.index);
    }

    /**
     * Update existing note
     */
    onSaveNote = () => {
        if (this.validate()) {
            this.setState({
                isEditMode: !this.state.isEditMode
            })
            let note = this.props.note;
            note.value = this.state.note;
            note.note_type = "TEXT";
            note.visibility = "ALL";
            this.props.updateNote(note);
        }
    }

    getOwnerName =() => {
        let name = "";
        let doctor;
        for (let i = 0; i < this.props.selectedAppointment.participants.length; i++) {
            if (this.props.selectedAppointment.participants[i].participant_role === "PROVIDER") {
                doctor = this.props.selectedAppointment.participants[i];
            }
        }
        if (this.props.note.owner_id === this.props.loggedUser.id) {
            name = $$("You")
        } else if (this.props.note.owner_id === doctor.user_id) {
            name = doctor.fullname;
        } else if (this.props.note.owner_fullname) {
            name = this.props.note.owner_fullname;
        }
        return name;
    }

    canEdit = () => {
        let app = this.props.selectedAppointment;
        return app.notes[0].id === this.props.note.id && //is latest note
            (app.status === 'ACCEPTED' ||
                (app.status === 'COMPLETED' && this.inAllowedToAnswerTimeRange()))  //its either in 'ACCEPTED' status or 'COMPLETED' and in time range (text consultations)
    }

    inAllowedToAnswerTimeRange = () => {
        let app = this.props.selectedAppointment;
        return app.completion_date + app.appointment_price.opened_communication_duration_days * 86400000 > new Date().getTime()
    }

    canDelete = () => {
        return false; //disable delete message for now
    }

    render() {
        const activeClass = classNames('appointment-containter', {
            'appointment-active': (this.props.selectedNoteId === this.props.note.id || this.props.selectedNoteId === this.props.index)
        });

        const activeDateClass = classNames('appointments-tab-column-label medrec-grey-2', {
            'medrec-blue-1': (this.props.selectedNoteId === this.props.note.id)
        });

        const activeNoteButtonsClass = classNames('appointments-tab-column-label medrec-blue-1 d-flex', {
            'note-hidden': (this.props.selectedNoteId !== this.props.note.id)
        });

        const noteTextClass = classNames('rounded-borders p-2 white-space-pre-line', {
            "background-light-red": !(this.props.note.owner_id === this.props.userId || this.props.note.owner_id === this.props.loggedUser.id),
            "background-light-green": this.props.note.owner_id === this.props.userId || this.props.note.owner_id === this.props.loggedUser.id,
        })

        let lang = _.contains(['en', 'sq'], this.props.i18n) ? 'en' : this.props.i18n;
        moment.locale(lang.lang);

        return (
            <div>
                <div id='appointment' className={activeClass} onClick={this.onNoteClick}>
                    <div className="row" style={{"width": "100%", "marginLeft": "20px", "marginRight": "20px"}}>
                        <div style={{"width": "93%"}}>
                            <div className="d-flex justify-content-end">
                                {this.props.loggedUser.id === this.props.note.owner_id && this.props.selectedNoteId === this.props.note.id &&
                                <div className={activeNoteButtonsClass}>
                                    {this.canDelete() && <a className="nav-link active" onClick={this.props.openDeleteModal}>
                                        <i className="kt-nav__link-icon flaticon-delete"/>&nbsp;{$$("delete_label")}
                                    </a>}
                                    {this.canEdit() && <a className="nav-link active" onClick={() => {
                                        if (!this.state.isEditMode) {
                                            this.setState({
                                                isEditMode: !this.state.isEditMode,
                                                note: this.props.selectedNote.value
                                            })
                                        }
                                    }}>
                                        <i className="kt-nav__link-icon flaticon-edit"/>&nbsp;{$$("edit_label")}
                                    </a>}
                                </div>}
                            </div>
                            <div className={activeDateClass}>
                                <i className="flaticon-calendar-with-a-clock-time-tools"/>&nbsp;&nbsp;&nbsp;&nbsp;
                                {dateTimeUtils.getFormattedDate(this.props.note.server_created_timestamp)} {dateTimeUtils.getFormattedTime(this.props.note.server_created_timestamp)}
                                &nbsp;({this.state.owner_fullname})
                            </div>
                            {!this.state.isEditMode &&
                            <div style={{"marginTop": "20px", "marginBottom": "20px"}} className={noteTextClass}>
                                {((this.props.note.value.length > 280) && !this.state.descriptionOpened) ? (this.props.note.value.substr(0, 279) + "...") : this.props.note.value}
                            </div>}
                            {!this.state.isEditMode && this.props.selectedNoteId === this.props.note.id && 
                                (this.props.note.value?.length < 280 || this.state.descriptionOpened) &&
                            <span className='note-links-wrap'><Links text={this.props.note.value} isSent={false} />
                            </span>}                            
                            {this.state.isEditMode &&
                            <div style={{"marginTop": "20px", "marginBottom": "20px"}}>
                                <form id="logbookForm" onSubmit={this.onSubmit} className={this.state.formclass}
                                      noValidate={true}>
                                    <div className="form-group">
                                        <div>
                                            <NoteSpeechToText
                                                onInputChange={this.onInputChange}
                                                note={this.state.note}
                                                onNoteSpeechChange={this.onNoteSpeechChange}
                                                i18n={this.props.i18n}
                                            />
                                        </div>
                                        <div className="invalid-feedback">
                                            {$$('note_required_message')}
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{
                                        "float": "right",
                                        "marginRight": "10px",
                                        "marginTop": "-5px",
                                        "zIndex": "100"
                                    }}>
                                        <div>
                                            <button type="button" className="btn btn-secondary btn-block"
                                                    onClick={() => {
                                                        this.setState({isEditMode: !this.state.isEditMode})
                                                    }}>
                                                {$$("cancel_btn")}
                                            </button>
                                        </div>
                                        <div style={{"marginLeft": "7px"}}>
                                            <button type="button" className="btn btn-success btn-block"
                                                    onClick={this.onSaveNote}>
                                                {$$("save_btn_label")}
                                            </button>
                                        </div>
                                    </div>
                                </form>                                
                            </div>}
                            {this.props.note.value?.length > 280 && !this.state.isEditMode && <div className="medrec-blue-1 text-right">
                                <a className="d-block smaller-text nav-link active" onClick={() => {
                                    this.setState({descriptionOpened: !this.state.descriptionOpened})
                                }}>
                                    {!this.state.descriptionOpened &&
                                        <span> {$$("more")}  <i className="kt-nav__link-icon fas fa-chevron-down"/></span>
                                    }
                                    {this.state.descriptionOpened &&
                                        <span> {$$("less")} <i className="kt-nav__link-icon fas fa-chevron-up"/> </span>
                                    }
                                </a>
                            </div>}
                        </div>                        
                    </div>
                </div>
            </div>
        )
    }
}

NotesTabListItem.propTypes = {
    i18n: PropTypes.object,
    index: PropTypes.number,
    note: PropTypes.object,
    onNoteChange: PropTypes.func,
    openDeleteModal: PropTypes.func,
    selectedAppointment: PropTypes.object,
    selectedNote: PropTypes.object,
    selectedNoteId: PropTypes.string,
    selectedUser: PropTypes.object,
    updateNote: PropTypes.func,
    userId: PropTypes.string,
};

export default NotesTabListItem;
