import React, {Component, useCallback, useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {$$, _$$} from '../../helpers/localization'
import {IssuePanel} from '../health/IssuePanel';
import MedicationsPanel from '../medications/MedicationsPanel';
import {Routes} from '../../constants/routes'
import {Link} from 'react-router-dom'
import {LatestMeasurementsPanel} from '../latest-measurements/LatestMeasurementsPanel'
import {CliniciansPanel} from '../clinicians/CliniciansPanel';
import {cliniciansProfileHelper} from '../../helpers/clinician_profile_helper'
import LandingPageCharts from "../charts/LandingPageCharts";
import {
    fetchSelectedUserLatestMeasurements,
    fetchChartsData,
    clearChartsData,
    clearLatestMeasurements,
} from '../../actions/users_actions'
import PropTypes from "prop-types";
import {
    clearHealthIssues,
    fetchSelectedUserHealthIssues
} from "../../actions/health-issues_actions";
import {clearProviders, fetchProviders} from "../../actions/provider_actions";
import {clearMedicationPlan, fetchSelectedUserMedicationPlan} from "../../actions/medications_actions";
import {getLatestValidVideoMessage} from "../../actions/chat_actions";
import useRoomState from "../video/hooks/useRoomState/useRoomState";
import useVideoContext from "../video/hooks/useVideoContext/useVideoContext";
import Button from "react-bootstrap/Button";
import UserImage from "../shared/UserImage";
import {VideoManager} from "../videocall/video_manager";
import {STARTING_CALL} from "../../constants/call_status";
import moment from "moment";
import {CONVERTER, formatDate} from "../../utils/converter";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {appointmentsService} from "../../service/appointments_service";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import {formatUtils} from "../../utils/formatUtils";
import {COMPLETED, IN_PROGRESS, INITIAL, REJECTED} from "../../constants/appointment_payment_status";
import {APPOINTMENTS_FILTER, getColorForOption} from "../../constants/select_options";
import history from "../../helpers/history"
import isToday from "date-fns/isToday";
import classNames from 'classnames'
import { Links } from '../messages/chatBoxWithSelectedUser/Message';

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            next_consultations:[]
        }
    }

    componentDidMount() {
        this.props.fetchSelectedUserLatestMeasurements(this.props.selectedUser.id);
        this.props.fetchSelectedUserHealthIssues(this.props.selectedUser.id);
        this.props.fetchProviders(null, this.props.selectedUser.country);
        this.props.fetchSelectedUserMedicationPlan(this.props.selectedUser.id)
        this.props.getLatestValidVideoMessage();
        appointmentsService.fetchNextAppointment(this.props.selectedUser.id).then(a=>{
            this.setState({next_appointment:a})
        }).catch(e=>{
            this.setState({next_appointment:undefined})
        });
        appointmentsService.fetchActiveConsultations(this.props.selectedUser.id).then(a=>{
            this.setState({next_consultations:a})
        }).catch(e=>{
            this.setState({next_consultations:[]})
        });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedUser.id !== prevProps.selectedUser.id) {
            this.props.fetchSelectedUserLatestMeasurements(this.props.selectedUser.id);
            this.props.fetchSelectedUserHealthIssues(this.props.selectedUser.id);
            this.props.fetchProviders(null, this.props.selectedUser.country);
            this.props.fetchSelectedUserMedicationPlan(this.props.selectedUser.id)
            this.props.getLatestValidVideoMessage();
            appointmentsService.fetchNextAppointment(this.props.selectedUser.id).then(a=>{
                this.setState({next_appointment:a})
            }).catch(e=>{
                this.setState({next_appointment:undefined})
            });
            appointmentsService.fetchActiveConsultations(this.props.selectedUser.id).then(a=>{
                this.setState({next_consultations:a})
            }).catch(e=>{
                this.setState({next_consultations:[]})
            });
        }
    }


    componentWillUnmount() {
        this.props.clearLatestMeasurements();
    }


    prepareClinicianData = (clinician) => {
        cliniciansProfileHelper.prepareData(clinician);
    }


    getHello() {
        let welcomeText;
        const today = new Date();
        const curHr = today.getHours();

        if (curHr < 12) {
            welcomeText = "good_morning"
        } else if (curHr < 18) {
            welcomeText = "good_afternoon"
        } else {
            welcomeText = "good_evening"
        }
        return <span>{$$(welcomeText) + ","}&nbsp;</span>
    }

    render() {
        return (
            <div className="wrapping-div landing-page">
                <h2 className="good-morning">
                    {this.getHello()}
                    <span className="font-weight-bold">{this.props.selectedUser.fullname.split(" ")[0]}!</span>
                </h2>
                <div className="landing">
                    <div className="landing-left">
                        {this.props.video_message.id && this.props.video.callStatus !== 'PRE_JOIN' &&
                            <div className="landing-video-session">
                                <LastVideoMessage video_message={this.props.video_message}/>
                            </div>}
                        {this.state.next_appointment && <NextAppointment appointment={this.state.next_appointment}/>}
                        {this.state.next_consultations.length > 0 && <div className="landing-medications">
                            <div className="dashboard-heading">
                                <span>
                                    {$$('active_text_consultations')}
                                </span>
                                <span>
                                    <Link className="small-link-blue"
                                          to={Routes.APPOINTMENTS}>{$$('see_all_label')}</Link>
                                </span>
                            </div>
                            {this.state.next_consultations.map(c => {
                                return <TextConsultation key={c.id} appointment={c}/>
                            })}
                        </div>}
                        <div className="landing-medications">
                            <div className="dashboard-heading">
                                <span>
                                    {$$('medications')}
                                </span>
                                <span>
                                    <Link className="small-link-blue"
                                          to={Routes.MEDICATIONS}>{$$('see_all_label')}</Link>
                                </span>
                            </div>
                            <div className="high-shadow-container-issues landing-container">
                                <MedicationsPanel
                                    {...this.props}
                                />
                            </div>
                        </div>
                        <div className="landing-charts">
                            <div className="dashboard-heading">
                                <span>
                                    {$$('charts')}
                                </span>
                                <span>
                                    <Link className="small-link-blue" to={{
                                        pathname: Routes.CHILD_MEDICAL_RECORD,
                                        state: {
                                            index: 3
                                        }
                                    }}>{$$('see_all_label')}</Link>
                                </span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <LandingPageCharts
                                    userId={this.props.selectedUser.id}
                                    fetchChartsData={this.props.fetchChartsData}
                                    clearChartsData={this.props.clearChartsData}
                                />
                            </div>
                        </div>
                        <div className="landing-issues">
                            <div className="dashboard-heading row">
                                <span>
                                    {$$('recent_health_issues')}
                                </span>
                                <span>
                                    <Link className="small-link-blue"
                                          to={Routes.HEALTH_ISSUES}>{$$('see_all_label')}</Link>
                                </span>
                            </div>
                            <div className="high-shadow-container-issues landing-container issues-container">
                                <IssuePanel
                                    {...this.props}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="landing-right">
                        <div className="landing-vitals">
                            <div className="dashboard-heading row">
                                <span>
                                    {$$('last_month_vitals')}
                                </span>
                                <span>
                                    <Link className="small-link-blue" to={{
                                        pathname: Routes.CHILD_MEDICAL_RECORD,
                                        state: {
                                            index: 1
                                        }
                                    }}>{$$('see_all_label')}</Link>
                                </span>
                            </div>
                            <LatestMeasurementsPanel
                                {...this.props}
                            />
                        </div>
                        <div className="landing-doctors">
                            <div className="dashboard-heading row">
                                <span>
                                    {$$('your_doctors')}
                                </span>
                                <span>
                                    <Link className="small-link-blue" to={{
                                        pathname: Routes.CLINICIANS
                                    }}>{$$('see_all_label')}</Link>
                                </span>
                            </div>
                            <div className="high-shadow-container-issues landing-container doctors-container">
                                <CliniciansPanel
                                    {...this.props}
                                    clinicians={this.props.providers}
                                    onClinicianClick={this.prepareClinicianData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LandingPage.propTypes = {
    dashboardCharts: PropTypes.object,
    fetchSelectedUserLatestMeasurements: PropTypes.func,
    healthIssues: PropTypes.array,
    history: PropTypes.object,
    i18n: PropTypes.object,
    latestUserMeasurements: PropTypes.array,
    location: PropTypes.object,
    match: PropTypes.object,
    medicationPlan: PropTypes.array,
    providers: PropTypes.array,
    selectedUser: PropTypes.object,
    userAppointments: PropTypes.array,
    userInfo: PropTypes.object,
    fetchChartsData: PropTypes.func,
    clearChartsData: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    clearHealthIssues: PropTypes.func,
    clearLatestMeasurements: PropTypes.func,
    fetchProviders: PropTypes.func,
    clearProviders: PropTypes.func,
    fetchSelectedUserMedicationPlan: PropTypes.func,
    clearMedicationPlan: PropTypes.func
}

function mapStateToProps(state) {

    return {
        dashboardCharts: state.dashboardCharts.chartsData,
        i18n: state.language,
        medicationPlan: state.medication.medicationPlan.entries,
        userInfo: state.userInfo.data,
        selectedUser: state.selectedUser.data,
        healthIssues: state.healthIssues.entries,
        latestUserMeasurements: state.latestUserMeasurements.entries,
        userAppointments: state.userAppointments.entries,
        providers: state.providers.entries,
        video_message: state.video_message,
        video: state.video.data
    }
}

const mapDispatchToProps = {
    fetchSelectedUserLatestMeasurements,
    fetchChartsData,
    clearChartsData,
    fetchSelectedUserHealthIssues,
    clearHealthIssues,
    clearLatestMeasurements,
    fetchProviders,
    clearProviders,
    fetchSelectedUserMedicationPlan,
    clearMedicationPlan,
    getLatestValidVideoMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

function useIsInCallWithSamePerson(callerId) {
    const [value, setValue] = useState(false);
    const { videoMessage }  = useVideoContext();
    const roomState = useRoomState();
    useEffect(() => {
        setValue(roomState === 'connected' && videoMessage && videoMessage.from_user_id === callerId)
    },[roomState, videoMessage]);

    return value
}

function LastVideoMessage({video_message}) {
    const callerId = video_message.from_user_id;
    const inCallWithSamePerson = useIsInCallWithSamePerson(callerId)

    const handleClick = () => {
        if (inCallWithSamePerson) {
            VideoManager.setState({modalShow:true})
        } else {
            VideoManager.setState({callStatus:STARTING_CALL, video_message: video_message})
        }
    }


    return (
        <div className="landing-medications" style={{marginBottom:"-3rem"}}>
            <div className="dashboard-heading">{$$("current_video_call")}</div>
            <div className="high-shadow-container d-flex align-items-center landing-container">
                <div className="p-2">
                    <div className='justify-content-center patient-img-container-list'>
                        <UserImage userID={video_message.from_user_id} classnames={"patient-img"}/>
                    </div>
                </div>
                <div className="p-2">
                    <span className='patient-fullname'>{video_message.from}</span>
                    <div className={"small"}>
                        {moment(video_message.date_time).format(CONVERTER.getSelectedDateTimeFormat(false))}
                    </div>
                </div>
                <div className="p-2">
                    <Button variant={"primary"} size={"sm"} onClick={handleClick}>{$$("join_now")}</Button>
                </div>
            </div>
        </div>
    )
}


function isOnSite(appointment) {
    return appointment.appointment_price.location_type === 'ON_SITE';
}

function getPracticeDetails(price) {
    let text = [price.org_name, price.org_address, price.org_city, price.org_phone_numbers, price.org_website].filter(Boolean).join(", ");
    return text;
}

function formatTime(message) {
    let date = message.server_modified_timestamp > 0 ? message.server_modified_timestamp : message.server_created_timestamp;
    return formatTimeAsString(date);
}

function formatTimeAsString(date) {
    return isToday(date) ? `${$$("today")},  ${dateTimeUtils.getFormattedTime(date)}` : CONVERTER.formatDate(date);
}

function canAnswer (app) {
    return app.completion_date + app.appointment_price.opened_communication_duration_days * 86400000 > new Date().getTime()
}

function consultationTimes(appointment) {
    if (appointment.text_only) {
        if (appointment.status === 'ACCEPTED' && appointment.first_response_time === 0 && appointment.payment_status === 'COMPLETED') {
            const time = CONVERTER.formatDate(appointment.ends_at)
            return <span>
                <br/>
                <span className="text-danger">{_$$("consultation_deadline_time", time)}</span>
                </span>
        }
        if (appointment.status === 'COMPLETED' && canAnswer(appointment)) {
            const time = CONVERTER.formatDate(appointment.completion_date + appointment.appointment_price.opened_communication_duration_days * 86400000)
            return <span>
                <br/>
                <span className="">{_$$("consultation_follow_up_deadline_time", time)}</span>
                </span>
        }
    }
}

function TextConsultation({appointment}) {

    const doctor = appointment.participants.find((p) => p.participant_role === "PROVIDER");

    const getNameCell = () => {
        const doctorImageClass = 'patient-img';
        return <div className="d-flex">
            <div className='justify-content-center patient-img-container-list'>
                <UserImage userID={doctor.user_id}
                           classnames={doctorImageClass}/>
            </div>
            <div style={{"alignSelf": "left"}}>
                <span className='patient-fullname'> {getClinicianNameWithTitle(doctor)}</span>
                <br/>
                <span className="medrec-grey-2 patient-age">{appointmentUtils.appointmentType(appointment.appointment_price)}</span>
                {consultationTimes(appointment)}
            </div>
        </div>
    }

    const getTimeCell = () => {
        return <div className='d-flex'>
            <div className="text-right">
                <span className='font-weight-bold'>
                    {dateTimeUtils.getLongFormattedDate(appointment.server_created_timestamp)}
                </span>
                <br/>
                <span className="">
                    <i className='flaticon2-time medrec-grey-2'/>
                    &nbsp; {CONVERTER.getEntryDateTimeFormat(appointment.server_created_timestamp)}
                </span>
            </div>
        </div>
    }

    const getPriceCell = () => {

        return <div className="payment-item">
            {appointment.appointment_price.price_cents === 0 ? $$('free_label') : formatUtils.currencyFormat(appointment.appointment_price.price_cents, appointment.appointment_price.currency)}
        </div>;
    }

    const getPaymentStatusCell = () => {
        const consultation_key_suffix = appointment.text_only ? "_consultation" : "";
        let label = "";
        if (appointment.appointment_price.price_cents === 0) {
            label = "";
        } else if (appointment.payment_status === COMPLETED) {
            label = $$('payment_completed' + consultation_key_suffix);
        } else if (appointment.payment_status === INITIAL) {
            label = <span className="text-danger">{$$('payment_initial' + consultation_key_suffix)}</span>;
        } else if (appointment.payment_status === IN_PROGRESS) {
            label = <span className="text-danger">{$$('payment_in_progress' + consultation_key_suffix)}</span>
        } else if (appointment.payment_status === REJECTED) {
            label = <span className="text-danger">{$$('payment_rejected'+ consultation_key_suffix)}</span>
        }


        let overdue = appointmentUtils.isOverdue(appointment) && appointment.appointment_price.location_type !== 'ON_SITE';
        let missedPayment = overdue && appointment.payment_status !== COMPLETED && appointment.payment_status !== IN_PROGRESS && appointment.appointment_price.price_cents > 0;
        label = missedPayment ? $$('missed_payment' + consultation_key_suffix) : label;

        let canPay = ((appointment.payment_status === INITIAL || appointment.payment_status === REJECTED)
            && (appointment.status === 'COMPLETED' || appointment.status === 'ACCEPTED') && appointment.appointment_price.price_cents > 0
            && appointment.appointment_price.currency === 'BGN')
            && !overdue;

        return <div className='d-flex'>
            <div className="payment-item">
                <span className={missedPayment ? "text-danger" : ""}>{label} {missedPayment &&
                <div className="">({formatDate(appointmentUtils.getAdjustedPayBefore(appointment), false)})</div>}</span>
                {canPay && appointment.appointment_price.location_type !== 'ON_SITE' &&
                <div className="">{$$("pay_before")}: {formatDate(appointmentUtils.getAdjustedPayBefore(appointment), false)}</div>}
            </div>
        </div>
    }

    const getStatusCell = () => {
        return <div className="status-cell-appointment">
            <span className="patient-age"
                  style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, appointment.status)}}>
                {$$(appointment.status.toLowerCase())}
            </span>
        </div>
    }


    const nameCell = useMemo(getNameCell, [appointment])
    const timeCell = useMemo(getTimeCell, [appointment])
    const statusCell = useMemo(getStatusCell, [appointment])
    const priceCell = useMemo(getPriceCell, [appointment])
    const paymentStatusCell = useMemo(getPaymentStatusCell, [appointment])

    const messageLabel = useCallback((message, patient) => {
        if (patient.user_id === message.owner_id) {
            return _$$("last_message", patient.fullname)
        } else {
            return $$("you_wrote")
        }
    }, [appointment])

    return <div className="high-shadow-container landing-container d-flex">
            <div className="d-flex flex-wrap flex-column flex-grow-1">
                <div className="d-flex justify-content-between">
                    {nameCell}
                    {paymentStatusCell}
                    {timeCell}
                </div>
                {/*<div className="d-flex pt-3 mt-3 border-top-light">
                    {statusCell}
                    {priceCell}
                    {paymentStatusCell}
                </div>*/}
                {appointment.notes.length > 0 && <div className="pt-3 mt-3 border-top-light lighter-font">
                    <div
                        className="medrec-grey-2 smaller-text pb-1">{formatTime(appointment.notes[0])} - {messageLabel(appointment.notes[0], doctor)}:
                    </div>
                    <div className={classNames("rounded-borders p-2 white-space-pre-line", {
                        "background-light-red" : doctor.user_id === appointment.notes[0].owner_id,
                        "background-light-green" : doctor.user_id !== appointment.notes[0].owner_id,
                    }
                    )}>
                        {appointment.notes[0].value}
                    </div>
                    <span className='note-links-wrap'><Links text={appointment.notes[0].value} isSent={false} /></span>
                </div>
                }
            </div>
            {/*<div className="d-flex align-items-center p-3 pointer" onClick={()=>{history.push(Routes.APPOINTMENTS)}}>
                <div className="text-center">
                    <i className="fa fa-chevron-right"></i>
                </div>
            </div>*/}
        </div>

}

function NextAppointment({appointment}) {

    const getNameCell = () => {
        const doctorImageClass = 'patient-img';
        const doctor = appointment.participants.find((p) => p.participant_role === "PROVIDER");
        return <div className="d-flex">
            <div className='justify-content-center patient-img-container-list'>
                <UserImage userID={doctor.user_id}
                           classnames={doctorImageClass}/>
            </div>
            <div style={{"alignSelf": "left"}}>
                <span className='patient-fullname'> {getClinicianNameWithTitle(doctor)}</span>
                <br/>
                <span
                    className="medrec-grey-2 patient-age">{appointmentUtils.appointmentType(appointment.appointment_price)}, <span
                    className="text-lowercase">{appointmentUtils.displayLocation(appointment)}</span></span>
                {isOnSite(appointment) && appointmentUtils.notOverridenLocation(appointment) && <br/>}
                {isOnSite(appointment) && appointmentUtils.notOverridenLocation(appointment) && <span className="text-thin">
                    <i className="fa fa-map-marker-alt mr-1" />
                    {getPracticeDetails(appointment.appointment_price)}
                </span>}
            </div>
        </div>
    }

    const getTimeCell = () => {
        return <div className='d-flex'>
            <div className="text-right">
                <span className='font-weight-bold'>
                    {dateTimeUtils.getLongFormattedDate(appointment.starts_at)}
                </span>
                <br/>
                <span className="">
                    <i className='flaticon2-time medrec-grey-2'/>
                    &nbsp; {CONVERTER.getEntryDateTimeFormat(appointment.starts_at)}
                </span>
            </div>
        </div>
    }

    const getPriceCell = () => {

        return <div className="payment-item">
            {appointment.appointment_price.price_cents === 0 ? $$('free_label') : formatUtils.currencyFormat(appointment.appointment_price.price_cents, appointment.appointment_price.currency)}
        </div>;
    }

    const getPaymentStatusCell = () => {
        let label = "";
        if (appointment.appointment_price.price_cents === 0) {
            label = "";
        } else if (appointment.payment_status === COMPLETED) {
            label = $$('payment_completed');
        } else if (appointment.payment_status === INITIAL) {
            label = $$('payment_initial');
        } else if (appointment.payment_status === IN_PROGRESS) {
            label = $$('payment_in_progress');
        } else if (appointment.payment_status === REJECTED) {
            label = $$('payment_rejected');
        }


        let overdue = appointmentUtils.isOverdue(appointment) && appointment.appointment_price.location_type !== 'ON_SITE';
        let missedPayment = overdue && appointment.payment_status !== COMPLETED && appointment.payment_status !== IN_PROGRESS && appointment.appointment_price.price_cents > 0;
        label = missedPayment ? $$('missed_payment') : label;

        let canPay = ((appointment.payment_status === INITIAL || appointment.payment_status === REJECTED)
            && (appointment.status === 'COMPLETED' || appointment.status === 'ACCEPTED') && appointment.appointment_price.price_cents > 0
            && appointment.appointment_price.currency === 'BGN')
            && !overdue;

        return <div className='d-flex'>
            <div className="payment-item">
                <span className={missedPayment ? "text-danger" : ""}>{label} {missedPayment &&
                <p className="small">({formatDate(appointmentUtils.getAdjustedPayBefore(appointment), false)})</p>}</span>
                {canPay && appointment.appointment_price.location_type !== 'ON_SITE' &&
                <p className="small">{$$("pay_before")}: {formatDate(appointmentUtils.getAdjustedPayBefore(appointment), false)}</p>}
            </div>
        </div>
    }

    const getStatusCell = () => {
        return <div className="status-cell-appointment">
            <span className="patient-age"
                  style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, appointment.status)}}>
                {$$(appointment.status.toLowerCase())}
            </span>
        </div>
    }


    const nameCell = useMemo(getNameCell, [appointment])
    const timeCell = useMemo(getTimeCell, [appointment])
    const statusCell = useMemo(getStatusCell, [appointment])
    const priceCell = useMemo(getPriceCell, [appointment])
    const paymentStatusCell = useMemo(getPaymentStatusCell, [appointment])

    return <div className="landing-medications">
        <div className="dashboard-heading">
                                <span>
                                    {$$('next_appointment')}
                                </span>
            {/*<span>
                                    <Link className="small-link-blue"
                                          to={Routes.MEDICATIONS}>{$$('see_all_label')}</Link>
                                </span>*/}

        </div>
        <div className="high-shadow-container landing-container d-flex">
            <div className="d-flex flex-wrap flex-column flex-grow-1">
                <div className="d-flex justify-content-between">
                    {nameCell}
                    {timeCell}
                </div>
                <div className="d-flex pt-3 mt-3 border-top-light">
                    {statusCell}
                    {priceCell}
                    {paymentStatusCell}
                </div>
            </div>
            <div className="d-flex align-items-center p-3 pointer" onClick={()=>{history.push(Routes.APPOINTMENTS)}}>
                <div className="text-center">
                    <i className="fa fa-chevron-right"></i>
                </div>
            </div>
        </div>

    </div>
}

